$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #666666 !default;//#adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


// SAP Farben
// Light theme flavor
$negative : lighten(#BB0000,8%);
$critical :lighten(#e78c07,8%);
$positive: #2b7d2b;
$neutral:#5e696e;
$information:#427cac;

/*

// Dark theme flavor
$negative :darken(#ff8888,10%);
$critical :darken(#fabd64,10%);
$positive: darken(#abe2ab,10%);
$neutral:darken(#d3d7d9,10%);
$information:#91c8f6;

*/


$blue: $information !default;//#20a8d8 !default;//#007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: $negative !default;//#dc3545 !default;
$orange: #fd7e14 !default;
$yellow: $critical !default;//#ffc107 !default;
$green:$positive !default;//#28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// global settings
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;


// the header
$header-bg: #2C4E6C; // $gray-900;//#2f3c48;
$header-color: #fafafa; //$gray-200;//#e9ecef;


$header-title-color: $gray-200; //#ebebeb;
$header-border-color: $gray-800; //#3f5161;

// brand colors
$brand-q-color: #91C8F6; //$red;
$brand-pilot-color: $header-color; //$gray-200;

// the side-bar
$sidebar-bg: #2C4E6C; //$gray-800;//#3f5161;
$sidebar-bg-border: lighten($sidebar-bg, 20%);
$sidebar-bg-hover: lighten($sidebar-bg, 5%);
$sidebar-bg-selected: complement($sidebar-bg);
$sidebar-item-bg: $sidebar-bg;


$sidebar-color: $gray-200;
$sidebar-icon-color: $gray-200;

// main area

$bg: $gray-200; //#ebebeb;


// base-card-header
$base-header-bg: $gray-100;
$base-header-color: $gray-900;


$tooltip-border-radius: 4px;
$tooltip-bg: $gray-700;





@import './variables.scss';

@import '~bootstrap/scss/bootstrap.scss';


@import "custom";

// Workaround fix für react-datepicker
// https://github.com/Hacker0x01/react-datepicker/issues/1674
//
@import "~react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker-wrapper{
  display:flex;
  flex:1 1 auto;
}
.react-datepicker__input-container{
  flex:1 1 auto;
}
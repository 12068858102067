.imagebox {
  position: relative;
  width: 100%;
  padding-top: 0;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;

    img {
      object-fit: contain;
    }
  }

}
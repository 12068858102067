.header {
  flex: 0 0 3rem;
  background-color: $header-bg;
  color: $header-color;
  justify-content: center;
  padding: 0.25rem 0;
  border-bottom: 1px solid $header-border-color;

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }


  .toggle-button {
    background-color: $header-bg;
    color: $header-color;
    border: 0;
    cursor: pointer;
  }

  .title {
    font-size: 1.2rem;
    color: $header-title-color;
    padding: 0.25rem 0;
  }

  .header-brand-q{
    color:$brand-q-color;
  }

  .header-brand-pilot{
    //font-family:"Century Gothic";
    //typeface:"Century Gothic";
    color:$brand-pilot-color;
    font-weight:300;
  }

  .header-user{
    background-color: $header-bg;
    border: 0;
    font-size: 14px;
    font-weight:300;
  }

}
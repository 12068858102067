$table-view-bg: white;

.table-view {
  flex: 1 1 auto;
  //background-color: $table-view-bg;
  display: flex;
  flex-direction: column;
  height:100%;
  //padding:0.5rem;

  .header {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 0 0 3rem;
    background-color: $prim_5up;
    color: black;
    border: 0;
    display: flex;
    align-items: center;


    .left {
      flex: 1 1 auto;
      color: $prim_4dn;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .right {
      flex: 1 1 auto;
      color: $prim_4dn;
      font-weight: bold;
      font-size: 1.5rem;
      float: right;
    }

  }

  .content {
    display: block;
    position: relative;
    overflow: auto;
    background-color: white;
    color:black;

    .dbgrid {
      height: 100%;

    }

  }


  .content2 {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
    //background-color: white;


    .dbgrid {

      flex: 1 1 60%;
      padding: 2rem;
      height: 100%;
    }

    .dbform {
      flex: 1 1 50%;
      margin: 2rem;
      color: black;
      background-color: white;
    }
  }

}

.tablecard {
  margin-bottom: 0.75rem;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);
  border-radius: 0;

  .card-header {
    border: 0;
    padding: 0.2rem 1rem;
  }

  .card-body {
    padding: 0;
  }

  .table {
    margin: 0;
  }

  td {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
}

.chartcard {
  margin-bottom: 0.75rem;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.15);
  border-radius: 0;

  .card-header {
    padding: 0.2rem 1rem;
  }

  .card-body {
    padding: 0;
  }
}

.table-page{
  display: flex;
  flex-direction: column;
  border: 0;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-scrolling: auto;

  > .row {
    margin-bottom: 1rem;
  }
}



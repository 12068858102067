body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: $bg;
  font-size: 0.875rem;
}



.view {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: #91C8F6;
  overflow: auto;

  .view-header {
    display: flex;
    flex: 0 0 100%;
    background-color: yellow;
  }

  .view-body {
    flex: 1 1 auto;
    background-color: $bg;
  }
}

// db-carousel
.db-carousel-image {
  min-width: 0;
  max-width: 100%;
  height: auto;
}

// formgroup row modus
.form-group {
  //margin-bottom: 0;

  label {
    margin-bottom: 0;
  }
}

.col-form-label {
  margin-top: 0;
  margin-bottom: 0;
}

@import 'colors';
@import 'header';
@import 'table-view';
@import 'ag';

@import 'base-card-grid';
@import 'imagebox';









.base-grid-card {
  color: black;
  position: relative;
  height: 100%;
  overflow: auto;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);
  border-radius:0.5rem;
  border: 0;

  .base-card-header {
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    display: flex;
    padding: 0.625rem ;
    padding-left: 1.25rem;
    padding-right: 0.5rem;
    background-color: $base-header-bg;
    color: $base-header-color;

    .right-header-menu {
      display: inline-block;
      height: 2rem;
      width: 2rem;
      margin-top: -6px;
      margin-bottom: -6px;
      padding: 0;
      border: 0;
      //borderRadius: 1rem;
      text-align: center;
      vertical-align: middle;
      background-color: $base-header-bg;
      color: $base-header-color;
    }

    .title{
      flex: 1 1 auto;
      text-align: center;
      font-weight:400;
    }
  }


  .card-grid-body {
    padding: 0;
    position: relative;
    height: 100%;

    border: 0;


    .grid-search {
      background-color: $gray-300;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border: 0;
      border-bottom: 1px solid $secondary;
    }

    .grid-content {
      display: flex;
      height: 100%;
      padding-top:2px;

      .grid-menu {
        flex: 0 1 2rem;
        background-color: $gray-100;

        .btn {
          padding: 0;
          width: 2rem;
          height: 2rem;
          border-radius: 0;
          margin: 0;
          margin-top: 2px;
        }


      }

      .grid {
        flex: 1 1 auto;
        max-width: 100%;
        overflow: auto;
      }
    }

  }

  .card-image-body {
    padding: 0;
    height: 400px;
    width: 100%;
  }

}